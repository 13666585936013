import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'

import requireAuth from './requireAuth'
import { changePassword } from '../actions/auth'
import { unload } from '../actions/settings'

const renderField = ({ input, label, type, meta }) => {
  return (
    <div className={`field ${meta.error && meta.touched ? 'error' : ''}`}>
      <label>{label}</label>
      <input {...input} type={type} />
    </div>
  )
}

const Settings = (props) => {
  const { unload } = props
  useEffect(() => {
    return () => {
      unload()
    }
  }, [unload])

  const onSubmit = async (formProps) => {
    try {
      await props.changePassword(formProps.password)
    } catch (e) {
      throw new SubmissionError({ _error: 'Failed to change password' })
    }
  }

  return (
    <div>
      <h1 className="ui header">Change password</h1>
      <form onSubmit={props.handleSubmit(onSubmit)} className="ui form error">
        <Field name="password" type="password" component={renderField} label="New password" />
        <Field name="confirmation" type="password" component={renderField} label="Confirm password" />
        <button disabled={props.pristine || props.submitting} className="ui button primary">
          Submit
        </button>
        {props.error && (
          <div className="ui error message">
            <div className="header">Invalid credentials</div>
            <p>Username or password is incorrect.</p>
          </div>
        )}
        {props.success && (
          <div className="ui positive message">
            <div className="header">Success</div>
            <p>Password changed successfully.</p>
          </div>
        )}
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.common.currentUser,
    success: state.settings.success,
  }
}

const mapDispatchToProps = { changePassword, unload }

const validate = values => {
  const errors = {}
  if (!values.password) {
    errors.password = 'Required'
  } else if (values.confirmation !== values.password) {
    errors.confirmation = 'Confirmation must match password'
  }
  return errors
}

export default compose(
  reduxForm({ form: 'settings', validate }),
  connect(mapStateToProps, mapDispatchToProps),
  requireAuth
)(Settings)
