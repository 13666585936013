import { APP_LOAD } from './types'
import { Auth } from '../api'

export const load = (token) => async (dispatch) => {
  if (!token) {
    dispatch({
      type: APP_LOAD,
      payload: { token: null, username: null },
    })
    return
  }

  try {
    const response = await Auth.current(token)
    dispatch({
      type: APP_LOAD,
      payload: {
        token: token,
        username: response.data.username,
      }
    })
  } catch {
    dispatch({
      type: APP_LOAD,
      payload: { token: null, username: null },
    })
  }
}
