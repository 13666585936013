import { APP_LOAD, LOGIN, LOGOUT } from './actions/types'

export const localStorageMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case LOGIN:
      if (!action.error) {
        window.localStorage.setItem('jwt', action.payload.token)
      }
      break;
    case LOGOUT:
      window.localStorage.setItem('jwt', '')
      break;
    case APP_LOAD:
      if (action.payload.token === null) {
        window.localStorage.removeItem('jwt')
      }
      break;
    default:
      break;
  }

  next(action)
}
