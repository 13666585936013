import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Redirect } from 'react-router'

import { login } from '../actions/auth'

const Login = (props) => {
  const onSubmit = async (formProps) => {
    try {
      await props.login(formProps.username, formProps.password)
    } catch (e) {
      throw new SubmissionError({ _error: 'Invalid login credentials' })
    }
  }

  if (props.currentUser) {
    return <Redirect to="/" />
  }

  return (
    <div>
      <form onSubmit={props.handleSubmit(onSubmit)} className="ui form error">
        <div className="field">
          <label>Username</label>
          <div>
            <Field name="username" component="input" type="text" />
          </div>
        </div>
        <div className="field">
          <label>Password</label>
          <div>
            <Field name="password" component="input" type="password" />
          </div>
        </div>
        <button disabled={props.pristine || props.submitting} className="ui button primary">
          Log in
        </button>
        {props.error && (
          <div className="ui error message">
            <div className="header">Invalid credentials</div>
            <p>Username or password is incorrect.</p>
          </div>
        )}
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.common.currentUser,
  }
}

const mapDispatchToProps = { login }

export default compose(
  reduxForm({ form: 'login' }),
  connect(mapStateToProps, mapDispatchToProps)
)(Login)
