import axios from 'axios'

const BASE_URL = '/api/admin'

const api = axios.create({
  baseURL: BASE_URL,
})

const auth = (token) => {
  return { headers: { 'Authorization': token } }
}

export const Auth = {
  current: (token) => api.get('/users', auth(token)),

  login: (username, password) => api.post('/login', { username, password }),

  changePassword: (token) => (password) => api.post('/changePassword', { password }, auth(token)),
}

export const Specification = (token) => ({
  all: () => api.get('/specifications', auth(token)),

  one: (specName) => api.get(`/specifications/${specName}`, auth(token)),

  remove: (specName) => api.delete(`/specifications/${specName}`, auth(token)),

  create: (identifier, format) => api.post('/specifications', { identifier, format }, auth(token))
})

export const Item = (token) => ({
  range: (specName, start, limit) => api.get(`/items/${specName}`, {
    ...auth(token),
    params: { start, limit }
  }),
})
