import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'

import requireAuth from './requireAuth'
import { createSpecification } from '../actions/create'

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <div className="ui error message">
        <div className="header">
          { error }
        </div>
      </div>
    )
  } else {
    return null
  }
}

const renderField = ({ input, label, type, meta }) => {
  return (
    <div className={`field ${meta.error && meta.touched ? 'error' : ''}`}>
      <label>{label}</label>
      <input {...input} type={type} />
      {renderError(meta)}
    </div>
  )
}

const SpecificationCreate = (props) => {
  const onSubmit = async (formProps) => {
    try {
      await props.createSpecification(formProps.identifier, JSON.parse(formProps.format))
    } catch (e) {
      throw new SubmissionError({ _error: 'Failed to create specification' })
    }
  }

  return (
    <div>
      <h1 className="ui header">Create specification</h1>
      <form onSubmit={props.handleSubmit(onSubmit)} className="ui form error">
        <Field name="identifier" type="text" component={renderField} label="Identifier" />
        <Field name="format" type="text" component={renderField} label="Format" />
        <button disabled={props.pristine || props.submitting} className="ui button primary">
          Submit
        </button>
        {props.error && (
          <div className="ui error message">
            <div className="header">Error</div>
            <p>An error occurred while creating the specification.</p>
          </div>
        )}
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.common.currentUser,
  }
}

const mapDispatchToProps = { createSpecification }

export const String = 'string'

export const Number = 'number'

export const Bool = 'boolean'

export const isType = (type) => {
  if (typeof(type) !== 'string') {
    return false
  }
  switch (type) {
    case String:
    case Number:
    case Bool:
      return true
    default:
      return false
  }
}

export const isIdentifier = (s) => {
  return /^[a-z0-9-]+$/.test(s)
}

const validate = values => {
  const errors = {}
  if (!isIdentifier(values.identifier)) {
    errors.identifier = 'Bad ID, must be [a-z0-9-]+'
  } else {
    try {
      const parsed = JSON.parse(values.format)
      Object.keys(parsed).forEach(key => {
        if (!(typeof(key) === 'string')) {
          errors.format = 'All keys must be strings'
          return errors
        }
        if (!isType(parsed[key])) {
          errors.format = `Key ${key} has bad type: ${parsed[key]}`
          return errors
        }
      })
    } catch {
      errors.format = 'Must be valid JSON'
    }
  }
  return errors
}

export default compose(
  reduxForm({ form: 'specification_create', validate }),
  connect(mapStateToProps, mapDispatchToProps),
  requireAuth
)(SpecificationCreate)
