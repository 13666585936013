import { push } from 'connected-react-router'

import { LOGIN, LOGOUT, PASSWORD_CHANGED, PASSWORD_CHANGE_SUBMITTED } from './types'
import { Auth } from '../api'

export const login = (username, password) => async (dispatch) => {
  const response = await Auth.login(username, password)
  dispatch({
    type: LOGIN,
    payload: response.data,
  })
  dispatch(push('/'))
}

export const changePassword = (password) => async (dispatch, getState) => {
  dispatch({
    type: PASSWORD_CHANGE_SUBMITTED
  })
  const token = getState().common.token
  await Auth.changePassword(token)(password)
  dispatch({
    type: PASSWORD_CHANGED,
  })
}

export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT })
  dispatch(push('/login'))
}
