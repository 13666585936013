export const APP_LOAD = 'APP_LOAD'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED'

export const PASSWORD_CHANGED = 'PASSWORD_CHANGED'
export const PASSWORD_CHANGE_SUBMITTED = 'PASSWORD_CHANGE_SUBMITTED'
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED'

export const DETAIL_PAGE_UNLOADED = 'DETAIL_PAGE_UNLOADED'
export const SPECIFICATION_LOADED = 'SPECIFICATION_LOADED'
export const ITEMS_LOADED = 'ITEMS_LOADED'
export const DETAIL_PAGE_CHANGE = 'DETAIL_PAGE_CHANGE'
export const DETAIL_PAGE_CHANGE_DELETE = 'DETAIL_PAGE_CHANGE_DELETE'
