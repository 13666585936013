import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'

import common from './common'
import home from './home'
import settings from './settings'
import detail from './detail'

const createRootReducer = (history) => combineReducers({
  common,
  home,
  settings,
  detail,
  router: connectRouter(history),
  form: formReducer,
})

export default createRootReducer
