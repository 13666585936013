import {
  DETAIL_PAGE_UNLOADED,
  SPECIFICATION_LOADED,
  ITEMS_LOADED,
  DETAIL_PAGE_CHANGE,
  DETAIL_PAGE_CHANGE_DELETE
} from '../actions/types'

const defaultState = {
  specification: null,
  currentPage: 0,
  items: {},
  deleting: false,
}

const detail = (state = defaultState, action) => {
  switch (action.type) {
    case DETAIL_PAGE_UNLOADED:
      return {...state, currentPage: 0, items: {}, deleting: false} // cache the specification itself
    case SPECIFICATION_LOADED:
      return {...state, specification: action.payload}
    case ITEMS_LOADED:
      if (!state.specification || action.payload.specification !== state.specification.identifier) {
        return state
      }
      const newItems = Object.fromEntries(action.payload.items.map((item, index) => [action.payload.start + index, item]))
      return {...state, items: {...state.items, ...newItems}}
    case DETAIL_PAGE_CHANGE:
      return {...state, currentPage: action.payload}
    case DETAIL_PAGE_CHANGE_DELETE:
      return {...state, deleting: action.payload}
    default:
      return state
  }
}

export default detail
