import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Loader from './Loader'
import requireAuth from './requireAuth'
import { load } from '../actions/home'

const Home = ({ specifications, load }) => {
  useEffect(() => {
    load()
  }, [load])

  return (
    <div>
      <div className="ui basic segment">
        <h1 className="ui header">
          Specifications
        </h1>
        {(specifications.length === 0) ? <Loader /> : (
          <div className="ui huge bulleted list">
            {specifications.map(spec => {
              return (
                <Link key={spec} className="item" to={`/specifications/${spec}`}>{spec}</Link>
              )
            })}
          </div>
        )}
      </div>
      <div className="ui basic segment">
        <Link to="/create">
          <button className="ui button primary">Create</button>
        </Link>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    specifications: state.home.specifications,
  }
}

const mapDispatchToProps = { load }

export default compose(
  requireAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(Home)
