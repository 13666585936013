import { HOME_PAGE_LOADED } from './types'
import { Specification } from '../api'

export const load = () => async (dispatch, getState) => {
  const token = getState().common.token
  const response = await Specification(token).all()
  dispatch({
    type: HOME_PAGE_LOADED,
    payload: {
      specifications: response.data
    }
  })
}
