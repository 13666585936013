import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import Loader from './Loader'
import Header from './Header'
import Home from './Home'
import Login from './Login'
import Logout from './Logout'
import Settings from './Settings'
import SpecificationDetail from './SpecificationDetail'
import SpecificationCreate from './SpecificationCreate'
import { load } from '../actions/app'
import style from './App.module.css'

const App = ({ appLoaded, currentUser, appName, load }) => {
  useEffect(() => {
    const token = window.localStorage.getItem('jwt')
    load(token)
  }, [load])

  return (
    <div className={`ui container ${style.root}`}>
      <Header appName={appName} currentUser={currentUser} />
      {!appLoaded ? <Loader /> : (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/specifications/:specification" component={SpecificationDetail} />
          <Route exact path="/create" component={SpecificationCreate} />
        </Switch>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appLoaded: state.common.appLoaded,
    currentUser: state.common.currentUser,
    appName: state.common.appName,
  }
}

const mapDispatchToProps = { load }

export default connect(mapStateToProps, mapDispatchToProps)(App)
