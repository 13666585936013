import { push } from 'connected-react-router'

import {
  SPECIFICATION_LOADED,
  ITEMS_LOADED,
  DETAIL_PAGE_CHANGE,
  DETAIL_PAGE_UNLOADED,
  DETAIL_PAGE_CHANGE_DELETE
} from './types'
import { Specification } from '../api'
import { Item } from '../api'

export const loadSpecification = (specName) => async (dispatch, getState) => {
  const token = getState().common.token
  const response = await Specification(token).one(specName)
  dispatch({
    type: SPECIFICATION_LOADED,
    payload: response.data
  })
}

export const loadItems = (specName, start, limit) => async (dispatch, getState) => {
  if (start < 0) {
    start = 0
  }
  const token = getState().common.token
  // fetch only what is necessary, so check what is in the range
  const items = getState().detail.items
  while (items.hasOwnProperty(start) && start < limit) {
    start++
  }
  while (items.hasOwnProperty(limit-1) && start < limit) {
    limit--
  }
  let data
  if (start < limit) {
    const response = await Item(token).range(specName, start, limit)
    data = response.data
  } else {
    data = []
  }
  dispatch({
    type: ITEMS_LOADED,
    payload: {
      start,
      limit,
      specification: specName,
      items: data
    }
  })
}

export const removeSpecification = (specName) => async (dispatch, getState) => {
  const token = getState().common.token
  await Specification(token).remove(specName)
  dispatch(push('/'))
}

export const page = (page) => ({
  type: DETAIL_PAGE_CHANGE,
  payload: page
})

export const unload = () => ({
  type: DETAIL_PAGE_UNLOADED
})

export const clickDelete = (value) => ({
  type: DETAIL_PAGE_CHANGE_DELETE,
  payload: value,
})
