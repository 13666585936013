import React from 'react'
import { Link } from 'react-router-dom'

const Header = ({ appName, currentUser }) => {
  let account

  if (currentUser) {
    account = (
      <>
        <Link className="item" to="/settings">Settings</Link>
        <Link className="item" to="/logout">Log out {currentUser}</Link>
      </>
    )
  } else {
    account = null
  }

  return (
    <div className="ui inverted menu">
      <Link className="item" to="/">
        {appName}
      </Link>
      <div className="right menu">
        {account}
      </div>
    </div>
  )
}

export default Header
