import { PASSWORD_CHANGE_SUBMITTED, PASSWORD_CHANGED, SETTINGS_PAGE_UNLOADED } from '../actions/types'

const defaultState = {
  success: false
}

const settings = (state = defaultState, action) => {
  switch (action.type) {
    case PASSWORD_CHANGED:
      return {...state, success: true}
    case PASSWORD_CHANGE_SUBMITTED:
    case SETTINGS_PAGE_UNLOADED:
      return {...state, success: false}
    default:
      return state
  }
}

export default settings
