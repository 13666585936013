import React from 'react'

// to silence warning: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/553#issuecomment-738933469

const ExternalButton = ({ children, ...props}) => {
  return (
    <a {...props}>{children}</a>
  )
}

export default ExternalButton
