import { HOME_PAGE_LOADED } from '../actions/types'

const defaultState = {
  specifications: []
}

const home = (state = defaultState, action) => {
  switch (action.type) {
    case HOME_PAGE_LOADED:
      return {...state, specifications: action.payload.specifications}
    default:
      return state
  }
}

export default home
