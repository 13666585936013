import { APP_LOAD, LOGIN, LOGOUT } from '../actions/types'

const defaultState = {
  appName: 'Telemetry',
  appLoaded: false,
  token: null,
  currentUser: null,
}

const common = (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {...state, appLoaded: true, token: action.payload.token, currentUser: action.payload.username}
    case LOGIN:
      return {...state, token: action.payload.token, currentUser: action.payload.username}
    case LOGOUT:
      return {...state, token: null, currentUser: null}
    default:
      return state
  }
}

export default common
