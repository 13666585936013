import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  loadSpecification,
  loadItems,
  page,
  unload,
  clickDelete,
  removeSpecification
} from '../actions/detail'
import requireAuth from './requireAuth'
import Paginate from './Paginate'
import Loader from './Loader'
import Modal from './Modal'

const LIMIT = 10
const PREFETCH = 10 * LIMIT

const range = (low, high) => {
  const arr = new Array(high-low+1)
  for (let i = 0; i < arr.length; i++) {
    arr[i] = low + i
  }
  return arr
}

const formatTime = (date) => {
  return moment(date).calendar()
}

const SpecificationDetail = (props) => {
  const specName = props.match.params.specification
  const {
    currentPage,
    specification,
    loadSpecification,
    items,
    loadItems,
    page,
    unload,
    deleting,
    clickDelete,
    removeSpecification
  } = props
  const start = currentPage * LIMIT

  useEffect(() => {
    return () => {
      unload()
    }
  }, [unload])

  useEffect(() => {
    // fetch if necessary
    if (!specification || specification.identifier !== specName) {
      loadSpecification(specName)
    }
  }, [specName, specification, loadSpecification])

  useEffect(() => {
    if (!specification || specification.identifier !== specName) {
      return
    }
    loadItems(specification.identifier, start-PREFETCH, start+LIMIT+PREFETCH)
  }, [specName, specification, start, loadItems])

  if (!specification || specification.identifier !== specName) {
    return <Loader />
  }

  const format = specification.format || {}

  return (
    <div>
      <h1>{specification.identifier}</h1>
      <div className="ui segment">
        <h2 className="ui header">Schema</h2>
        <div className="ui form">
          <textarea readOnly value={JSON.stringify(format, null, 2)} />
        </div>
        <div className="ui basic segment">
          <div className="ui form">
            <button className="ui button negative" onClick={() => clickDelete(true)}>Delete</button>
            {!deleting ? null : (
              <Modal
                onDismiss={() => clickDelete(false)}
                title="Confirm Deletion"
                content={<>Are you sure you want to delete <b>{specification.identifier}</b>?</>}
              >
                <button className="ui button negative" onClick={() => removeSpecification(specification.identifier)}>Delete</button>
                <button className="ui button" onClick={() => clickDelete(false)}>Cancel</button>
              </Modal>
            )}
          </div>
        </div>
      </div>
      <div className="ui segment">
        <h2 className="ui header">Data</h2>
        <Paginate page={currentPage} onPageChange={(value) => page(parseInt(value) || 0)} />
        <table className="ui striped celled table">
          <thead>
            <tr>
              <th>time</th>
              <th>ip</th>
              {Object.keys(format).map(col => <th key={col}>{col}</th>)}
            </tr>
          </thead>
          <tbody>
            {range(start, start+LIMIT).map(i => {
              const item = items[i]
              if (!item) {
                return null
              }
              return (
                <tr key={item.id}>
                  <td>{formatTime(new Date(item.date))}</td>
                  <td>{item.ip}</td>
                  {Object.keys(format).map(col => <td key={col}>{item.data[col].toString()}</td>)}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    specification: state.detail.specification,
    items: state.detail.items,
    deleting: state.detail.deleting,
    currentPage: state.detail.currentPage,
  }
}

const mapDispatchToProps = {
  loadSpecification,
  loadItems,
  page,
  unload,
  clickDelete,
  removeSpecification
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  requireAuth
)(SpecificationDetail)
