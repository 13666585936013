import React, { useRef } from 'react'
import ReactDOM from 'react-dom'

const Modal = (props) => {
	const ref = useRef()

	const onBackgroundClick = (e) => {
		if (e.target === ref.current) {
			props.onDismiss()
		}
	}

	return ReactDOM.createPortal(
		<div ref={ref} className="ui dimmer modals visible active" onClick={onBackgroundClick}>
			<div className="ui standard modal visible active">
				<i className="close icon" onClick={props.onDismiss} />
				<div className="header">{props.title}</div>
				<div className="content">{props.content}</div>
				<div className="actions">
					{props.children}
				</div>
			</div>
		</div>,
		document.querySelector('#modal')
	)
}

export default Modal
