import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

const requireAuth = (Component) => (props) => {
  const currentUser = useSelector((state) => state.common.currentUser)
  if (!currentUser) {
    return <Redirect to="/login" />
  }
  return <Component {...props} />
}

export default requireAuth
