import React from 'react'

import ExternalButton from './ExternalButton'

const Paginate = ({ page, onPageChange }) => {
  const onPrevious = (e) => {
    if (page > 0) {
      onPageChange(page-1)
    }
  }
  const onChange = (e) => {
    onPageChange(e.target.value)
  }
  const onNext = (e) => {
    onPageChange(page+1)
  }

  return (
    <div className="ui centered grid">
      <div className="center aligned column">
        <div className="ui compact menu">
          <ExternalButton className={`item ${page === 0 ? 'disabled' : ''}`} onClick={onPrevious}>
            <i className="angle double left icon"></i> Previous
          </ExternalButton>
          <div className="item">
            <div className="ui input">
              <input type="text" value={page} onChange={onChange} />
            </div>
          </div>
          <ExternalButton className="item" onClick={onNext}>
            <i className="angle double right icon"></i> Next
          </ExternalButton>
        </div>
      </div>
    </div>
  )
}

export default Paginate
